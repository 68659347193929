
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  display: flex;
  height: 47px;
  background: $white100;
  padding: 0 12px;
  width: 100%;
  border-bottom: 1px solid $white400;
  flex-shrink: 0;
}

.tab {
  display: flex;
  align-items: center;
  padding: 12px 0;
  margin: 0 12px;
  border: none;
  outline: none;
  background: $white100;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  color: $black100;
  @include element-transition;

  svg {
    margin-right: 8px;
    path {
      @include element-transition;
      fill: $gray100;
    }
  }

  &.tabItemActive,
  &:hover {
    box-shadow: inset 0px -1px 0px $blue400;
    color: $blue400;

    svg path {
      fill: $blue400;
    }
  }

  &:disabled {
    @include button-disabled;
  }

  .warningIcon {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: $yellow100;
    margin-left: 8px;
  }
}
