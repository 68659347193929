
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(inline-flex, row, center);
  background: $shadowGradient100;
  border-radius: 6px;
  position: relative;
  color: $black400;
  max-width: 510px;
  box-shadow: 0 1px 2px $shadow200, 0 4px 12px $shadow200;

  .text {
    text-align: left;
    padding: 12px 16px;
    line-height: 22px;
    word-break: break-word;
    @include flex-container(flex, column);
    gap: 10px;

    &.hasIcon {
      padding-right: 38px;
    }
  }

  p {
    white-space: pre-wrap;
    text-align: left;
  }

  &.error {
    border: 1px solid $red100;
  }

  &.pending {
    opacity: 0.5;
  }

  &.messageOwner {
    background: $white100;

    .textContainer {
      flex-direction: row-reverse;
    }
  }

  .attachmentContainer {
    width: 100%;
  }

  .massIcon {
    @include flex-container(flex, row, flex-end);
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    padding: 7px;
    height: 100%;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
