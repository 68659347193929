
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.searchField {
  width: 100%;

  .checkIcon,
  .infoIcon {
    @include flex-container(flex, row, center);
  }

  .checkIcon {
    svg path {
      fill: $green100;
    }
  }

  .infoIcon {
    svg path {
      fill: $gray100;
    }
  }
}

.triggerContainer {
  width: 100%;
}

.contentContainer {
  width: 100%;
  max-height: 384px;
  position: relative;
  min-height: 60px;
}
