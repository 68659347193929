
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column, flex-start);
  text-align: left;

  .message {
    @include flex-container(inline-flex, row);
    word-break: break-word;
    background-color: $white100;
    border: 1px solid $white600;
    border-radius: 6px;
    padding: 8px 12px;
    color: $black400;
    line-height: 22px;
    box-shadow: 0 1px 2px $shadow100, 0 4px 12px $shadow300;
    p {
      white-space: pre-wrap;
      text-align: left;
    }

    .starIcon {
      display: flex;
      margin-right: 10px;
      margin-top: 3px;
      svg path {
        fill: $yellow100;
      }
    }
  }

  .dateTime {
    @include flex-container(flex, row, center);
    margin-top: 6px;

    p {
      font-size: 12px;
      line-height: 16px;
      color: $blue500;
      opacity: 0.5;
      margin-right: 4px;
    }
  }

  .redirectButton {
    @include flex-container(flex, row, center, center);
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: $white400;
    cursor: pointer;

    &:hover {
      @include button-hover;
    }

    &:active {
      @include button-active;
    }
  }

  .attachment {
    margin-bottom: 10px;
  }
}
