
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  position: relative;
  width: max-content;

  .buttonTitle {
    white-space: nowrap;
    margin: 0 8px;
  }

  .dropdownContent {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    background: $white100;
    z-index: 1;

    &.bottom {
      bottom: 0;
    }

    &.top {
      top: 0;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  &.fullWidth {
    width: 100%;
    .button {
      width: 100%;
    }
  }

  .button {
    @include flex-container(flex, row, center, center);
    position: relative;
    font-weight: bold;

    &.buttonWithIcon {
      .buttonTitle {
        margin: 0;
      }
    }

    &.buttonWithoutIcons {
      .buttonTitle {
        margin: 0;
      }
    }

    .buttonDropdown {
      width: auto;
      display: flex;

      .iconContainer {
        display: inline-flex;
        padding-left: 8px;
      }
    }

    .content {
      @include flex-container(inline-flex, row, center, space-between);
    }

    &:not([disabled]) {
      &:hover {
        @include button-hover;
      }

      &:active {
        @include button-active;
      }
    }

    &:disabled {
      .buttonTitle {
        opacity: 0.6;
      }

      svg {
        opacity: 0.6;
      }
    }

    &.buttonSmall {
      font-size: 14px;
      line-height: 18px;
      height: 34px;
      border-radius: 31px;

      .content {
        padding: 8px 16px;
      }

      .iconContainer {
        padding-right: 16px;
      }

      &.buttonWithIcon {
        .content {
          padding: 8px 9px;
        }

        .iconContainer {
          padding-right: 9px;
        }
      }
    }

    &.buttonDefault {
      font-size: 16px;
      line-height: 22px;
      height: 38px;
      border-radius: 31px;

      .content {
        padding: 8px 16px;
      }

      .iconContainer {
        padding-right: 16px;
      }

      &.buttonWithIcon {
        .content {
          padding: 8px 11px;
        }

        .iconContainer {
          padding-right: 11px;
        }
      }
    }

    &.buttonLarge {
      font-size: 16px;
      line-height: 22px;
      height: 46px;
      border-radius: 29px;

      .content {
        padding: 12px 24px;
      }

      .iconContainer {
        padding-right: 24px;
      }

      &.buttonWithIcon {
        .content {
          padding: 8px 15px;
        }

        .iconContainer {
          padding-right: 15px;
        }
      }
    }

    &.buttonText {
      &.buttonPrimary {
        color: $blue300;
        background: transparent;

        .iconContainer {
          border-left: 1px solid $blue300;
        }

        svg path {
          fill: $blue300;
        }
      }

      &.buttonSuccess {
        color: $green100;
        background: transparent;

        .iconContainer {
          border-left: 1px solid $green100;
        }

        svg path {
          fill: $green100;
        }
      }

      &.buttonDanger {
        color: $red100;
        background: transparent;

        .iconContainer {
          border-left: 1px solid $red100;
        }

        svg path {
          fill: $red100;
        }
      }

      &:disabled {
        cursor: default;
        color: $blue300;
        border-color: $gray100;

        svg path {
          fill: $blue300;
        }
      }
    }

    &.buttonContained {
      &.buttonPrimary {
        color: $blue300;
        background: $white200;

        .iconContainer {
          border-left: 1px solid $blue300;
        }

        svg path {
          fill: $blue300;
        }
      }

      &.buttonSuccess {
        color: $white100;
        background: $green100;

        .iconContainer {
          border-left: 1px solid $white200;
        }

        svg path {
          fill: $white100;
        }
      }

      &.buttonDanger {
        color: $white100;
        background: $red100;

        .iconContainer {
          border-left: 1px solid $white200;
        }

        svg path {
          fill: $white100;
        }
      }

      &:disabled {
        cursor: default;
        color: $blue300;
        background: $white200;
        svg path {
          fill: $blue300;
        }
      }
    }

    &.buttonOutlined {
      &.buttonPrimary {
        color: $blue300;
        background: transparent;
        box-shadow: 0 0 0 2px $gray100 inset;

        .iconContainer {
          border-left: 1px solid $blue300;
        }

        svg path {
          fill: $blue300;
        }
      }

      &.buttonSuccess {
        color: $green100;
        background: transparent;
        border: 2px solid $green100;

        .iconContainer {
          border-left: 1px solid $green100;
        }

        svg path {
          fill: $green100;
        }
      }

      &.buttonDanger {
        color: $red100;
        background: transparent;
        border: 2px solid $red100;

        .iconContainer {
          border-left: 1px solid $red100;
        }

        svg path {
          fill: $red100;
        }
      }

      &:disabled {
        cursor: default;
        color: $blue300;
        border-color: $gray100;

        svg path {
          fill: $blue300;
        }
      }
    }
  }

  &.dropdownOpened {
    .dropdownContent {
      display: flex;
    }

    .button {
      .buttonDropdown {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  .loader svg,
  svg {
    width: 16px;
    height: 16px;
  }
}
