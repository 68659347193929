
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, flex-start);

  &.right {
    justify-content: flex-start;
    text-align: right;
    flex-direction: row-reverse;

    .textContainer {
      flex-direction: row-reverse;
    }

    .avatar {
      margin-left: 10px;
    }
  }

  &.left {
    text-align: left;
    .avatar {
      margin-right: 10px;
    }
  }

  .textContainer {
    @include flex-container(inline-flex, row);
  }

  .dateTime {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
  }

  .transparentText {
    opacity: 0.5;
  }

  .redText {
    color: $red100;
  }

  .retryLink {
    color: $red100;
    display: inline;
    margin: 0 2px;
    font-size: 12px;
    line-height: 16px;
  }

  .starButton {
    margin: 4px;

    &:hover {
      background-color: transparent;
    }

    &:active svg path {
      fill: transparent;
    }

    svg {
      width: 15px;
      height: 15px;

      path {
        fill: transparent;
      }
    }

    &.starred {
      svg path {
        fill: $yellow100;
        stroke: $yellow100;
      }
    }
  }
}
