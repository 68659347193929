
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row);
  border-top: 1px solid $gray100;
  width: 100%;
  padding: 10px;
  gap: 10px;
}

.dropdownContainer {
  flex: 0;
}

.dropdownContent {
  width: 100% !important;
  height: calc(100% - 221px);
}
