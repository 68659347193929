
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(inline-flex, row, center, center);

  .note {
    width: 100%;
    height: 100%;

    > div {
      width: 100%;
      height: 100%;
    }

    textarea {
      resize: none;
      border: none;
      outline: none;
      padding: 16px;
      font-size: 16px;
      background-color: #fffcf5;
      width: 100%;
      height: 100%;
    }
  }
}
