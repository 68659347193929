
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column, center, center);
  background: $white400;
  height: 100%;
  width: 100%;
  border-radius: 4px;

  p {
    text-transform: uppercase;
    font-size: 12px;
    color: $blue300;
  }
}
