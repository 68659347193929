
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  width: 100%;
  height: 100%;
  background: $white100;

  .content {
    width: 100%;
    height: 100%;
  }
}

.overlay {
  @include flex-container(flex, row, center, center);
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: $overlay;
  animation: fadeIn ease-in 0.1s;
  will-change: opacity;
  animation-fill-mode: forwards;

  &.removing {
    animation: fadeOut ease-out 0.3s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
