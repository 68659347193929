
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  height: 100%;
  width: 100%;

  .header {
    @include flex-container(flex, row, center, space-between);
    border-bottom: 1px solid $white400;
  }

  .content,
  .controls,
  .header {
    width: 100%;
    padding: 12px 24px;
  }

  .content {
    overflow-y: auto;
    height: 100%;
  }

  .field {
    margin-bottom: 15px;
  }

  .controls {
    @include flex-container(flex, column, flex-start, flex-start);
    padding: 16px 24px;
    border-top: 1px solid $white400;
    border-bottom: 1px solid $white400;
  }

  .buttons {
    @include flex-container(flex, row, center, flex-end);
    width: 100%;
  }

  .verifying {
    width: 100%;
    @include flex-container(flex, row, center, center);
    margin-bottom: 10px;

    .infoIcon {
      @include flex-container(flex, row, center, center);
      margin-left: 10px;
      svg path {
        fill: $gray100;
      }
    }
  }

  .errorMessage {
    margin-bottom: 15px;
    color: $red100;
  }
}
