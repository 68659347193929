
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, flex-end, space-between);
  @include element-transition;
  padding: 12px 16px;
  width: 100%;
  min-height: 62px;
  color: $blue500;
  border-radius: 8px;
  cursor: pointer;
  gap: 10px;

  &:hover {
    background-color: $gray300;
  }

  &:active {
    @include button-active;
  }

  &.selected {
    background-color: $gray300;
  }

  &.unreadBadge {
    .title {
      font-weight: bold;
    }
  }

  .messageContainer {
    @include flex-container(flex, column, flex-end);
    width: 100%;
    overflow: hidden;
  }

  .titleContainer {
    @include flex-container(flex, row, center);
    width: 100%;
    font-size: 16px;
    line-height: 22px;

    svg {
      width: 14px;
      height: 14px;
      flex-shrink: 0;

      path {
        fill: $blue300;
      }
    }

    .title {
      @include text-ellipsis;
      margin-left: 6px;
    }
  }

  .dateTimeContainer {
    @include flex-container(flex, column, flex-end, flex-end);
    flex-shrink: 0;
    .time,
    .date {
      font-size: 12px;
      line-height: 16px;
      opacity: 0.65;
      text-align: right;
    }

    .date {
      margin-top: 5px;
    }
  }

  .message {
    @include flex-container(flex, row);
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    margin-top: 2px;
    opacity: 0.5;

    .text {
      margin-left: 6px;
      @include text-ellipsis;
    }

    .arrowIcon {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
    }
  }

  .counter {
    background-color: $blue600;
    padding: 1px 5px;
    border-radius: 20px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    color: $white100;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    margin-left: 10px;
  }
}
