
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: relative;

  .emptyMessage {
    width: 100%;
    min-height: 60px;
    height: 100%;
    bottom: 0;
    top: 0;
    @include flex-container(flex, row, center, center);
    white-space: pre-wrap;
  }
}
