$white100: #fff;
$white200: #f6f9fa;
$white300: #f7fafb;
$white400: #e8f1f7;
$white500: #ebf5fe;
$white600: #cbdfe9;
$gray100: #c0d3e5;
$gray200: #e5e5e5;
$gray300: #dbe6f0;
$gray400: #c7d8e8;
$gray500: #d1e6fa;
$gray600: #e5eff4;
$gray700: #6e7781;
$blue100: #93acc4;
$blue200: #758d98;
$blue300: #7b93ab;
$blue400: #3597f1;
$blue500: #202b36;
$blue600: #0d77d8;
$blue700: #3d4750;
$black100: #2c2932;
$black300: #1d2429;
$black400: #000000;
$green100: #22a837;
$green200: #1a7528;
$red100: #f4431c;
$red200: #c62906;
$orange100: #f46210;
$yellow100: #ffc700;
$yellow200: #fff4cc;
$yellow300: #f6d150;
$overlay: rgba($blue500, 0.5);
$shadow100: rgb(0 0 0 / 5%);
$shadow200: rgb(0 0 0 / 10%);
$shadow300: rgb(0 0 0 / 3%);
$shadowGradient100: linear-gradient(0deg, #d1e6fa, #d1e6fa), #d3e7fa;
$fontPrimary: 'Rubik', sans-serif;
