
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  width: 100%;
  height: 100%;
  background-color: $white100;

  .header {
    @include flex-container(flex, row, center, space-between);
    height: 54px;
    width: 100%;
    padding: 16px;

    h4 {
      @include text-ellipsis;
      margin: 0;
      width: 100%;
    }

    .controls {
      @include flex-container(flex, row, center, flex-start);
      gap: 24px;
    }
  }

  .image {
    height: calc(100% - 54px);

    img {
      object-fit: contain;
    }
  }
}
