
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column, center, center);
  height: 100%;
  width: 100%;

  .textContainer {
    text-align: center;
    max-width: 230px;
    margin-top: 24px;
  }

  svg {
    width: 64px;
    height: 64px;
    path {
      fill: $gray100;
    }
  }
}
