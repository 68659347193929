
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  display: flex;

  .dropdownContainer {
    width: 100%;
  }

  .emojiPickerContainer,
  .templatesContainer {
    width: 100%;
    max-width: 800px;
    background-color: $white100;
    border: 1px solid $gray300;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.1);
  }

  .templatesContainer {
    @include flex-container(flex, column, center, center);

    .templateButton {
      @include flex-container(flex, row, center);
      padding: 8px 16px;
      width: 100%;
      cursor: pointer;

      &:hover {
        @include button-hover;
        background-color: $white400;
      }

      &:active {
        @include button-active;
      }
    }
  }

  .templateEmptyMessage {
    padding: 8px 16px;
  }

  .controlsButton {
    margin-right: 2px;
  }

  .detailsContainer {
    width: 310px;
    height: 298px;
    overflow-y: auto;
    padding: 12px;
    background-color: $white100;
    border: 1px solid $gray300;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05), 0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .detailsTag {
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .detailsTitle {
    margin: 5px 0 10px;
  }
}
