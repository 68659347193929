
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column, flex-end, flex-start);
  width: 100%;
  padding: 16px;
  background-color: $white200;

  .saveButton {
    margin-top: 8px;
  }

  .textArea {
    font-size: 14px;

    ::placeholder {
      font-size: 14px;
    }
  }
}
