
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  position: relative;
  padding: 12px 8px 12px 12px;
  overflow-y: scroll;

  .channel {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
}
