
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, center);
  @include element-transition;

  width: 100%;
  cursor: pointer;
  padding: 8px 16px;
  background-color: $white100;
  white-space: nowrap;

  &:hover {
    background-color: $white200;
  }

  &.includesTitle {
    pointer-events: none;
  }

  &.divider {
    box-shadow: inset 0 -1px 0 $gray400;
  }

  &.active {
    background-color: $white400;
  }

  .subTitle {
    color: $blue200;
  }
}
