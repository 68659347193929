
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(inline-flex, row, center);
  max-width: 100%;
  border: 1px solid $gray100;
  background-color: $white100;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    @include button-hover;
  }

  &:active {
    @include button-active;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
  }

  .content {
    font-size: 14px;
    line-height: 18px;
    max-width: 170px;
    padding: 3px 10px;
    @include text-ellipsis;

    &.withIcon {
      padding-left: 0;
    }

    &.withRemoveButton {
      padding-right: 0;
    }
  }

  .icon {
    padding: 3px 5px 3px 10px;

    svg {
      @include flex-container(inline-flex, row, center);
      width: 12px;
      height: 12px;

      path {
        fill: $blue300;
      }
    }
  }

  .remove {
    height: auto !important;
    width: auto !important;
    align-self: stretch;
    margin-left: 5px;
    border-radius: 0;
    padding: 3px 10px;

    &:hover {
      border-left: 1px solid $gray100;
    }

    svg {
      width: 8px !important;
      height: 8px !important;

      path {
        fill: $blue300;
      }
    }
  }
}
