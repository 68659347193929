
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, center);

  border-radius: 5px;
  font-weight: normal;
  color: $blue500;

  svg path {
    fill: $blue300;
  }

  &:hover {
    @include button-hover;
  }

  &:active,
  &.activated {
    svg path {
      fill: $blue400;
    }
  }

  &:active {
    @include button-active;
  }

  &:disabled {
    @include button-disabled;
  }

  &.text {
    background: transparent;
    border: 1px solid transparent;

    &:active {
      background-color: $white400;
    }

    &.activated {
      background-color: $white400;
      border: 1px solid $gray100;
    }

    &:hover {
      @include button-hover;
      background-color: $white400;
    }
  }

  &.contained {
    background-color: $white400;
    border: 1px solid transparent;
  }

  &.outlined {
    background-color: $white100;
    border: 1px solid $gray100;

    &:active,
    &.activated {
      background-color: $white400;
    }
  }

  &.small {
    font-size: 14px;
    line-height: 18px;
    height: 34px;
    padding: 8px 12px;

    &.withoutText {
      padding: 8px;
    }

    svg,
    .loader svg {
      width: 16px;
      height: 16px;
    }
  }

  &.default {
    font-size: 16px;
    line-height: 22px;
    height: 38px;
    padding: 4px 12px;

    &.withoutText {
      padding: 8px;
      justify-content: center;
    }

    svg,
    .loader svg {
      width: 20px;
      height: 20px;
    }
  }

  .buttonTitle {
    @include text-ellipsis;
    @include flex-container(flex, row, center, center);
    flex-grow: 1;
  }

  .content {
    @include flex-container(flex, row, center);
    width: 100%;
    height: 100%;

    &.rightIcon {
      flex-direction: row-reverse;

      svg {
        margin-left: 8px;
      }
    }

    &.leftIcon {
      svg {
        margin-right: 8px;
      }
    }

    &.withoutText {
      svg {
        margin: 0;
      }
      justify-content: center;
    }
  }

  .loader {
    margin-left: 8px;
  }

  &.withoutText {
    .loader {
      margin: 0;
    }
  }
}
