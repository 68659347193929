
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  .hiddenCheckbox {
    visibility: hidden;
    width: 1px;
  }

  .styledCheckbox {
    @include flex-container(inline-flex, row, center, center);
    @include element-transition;

    width: 20px;
    height: 20px;
    background: $white100;
    border-radius: 4px;
    border: 1px solid $gray100;
    cursor: pointer;

    &:hover {
      background: $white500;
    }

    svg {
      visibility: hidden;
      width: 12px;
      height: 12px;

      path {
        fill: $white100;
      }
    }

    &.checked {
      border: none;
      background: $blue400;

      svg {
        visibility: visible;
      }
    }
  }

  .label {
    margin-left: 7px;
  }
}
