
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, center, space-between);
  width: 100%;
  height: 60px;
  background-color: $white100;
  border-bottom: 1px solid $white400;
  position: relative;

  .nameContainer {
    padding: 12px 16px;

    h4 {
      line-height: 18px;
    }

    h5 {
      color: $blue500;
      opacity: 0.5;
    }
  }

  .newChatButton {
    margin-right: 16px;
  }
}
