
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  width: 100%;
  height: 100%;
  transition: filter 0.3s ease-out;
  cursor: pointer;
  background: $white400;
  border-radius: 4px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
