
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, center, flex-start);
  padding: 8px 16px;
  box-shadow: inset 0px -1px 0px $gray400;
  cursor: pointer;

  .name {
    margin-left: 16px;
  }

  .telephone {
    margin-top: 5px;
    @include flex-container(flex, row, center);

    svg {
      width: 12px;
      height: 12px;
      margin-right: 5px;

      path {
        fill: $blue300;
      }
    }
  }

  &:hover {
    @include button-hover;
  }
}
