
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  width: 100%;

  .mediaPreview {
    img,
    video {
      width: 100%;
      min-width: 280px;
      max-width: 350px;
      height: 240px;
    }

    audio {
      width: 100%;
      min-width: 280px;
      max-width: 350px;
    }
  }
}
