
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  width: 100%;
  position: relative;
  height: 100%;

  .entriesContainer {
    padding: 15px;

    .message {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 25px 0 40px 0;
    background: linear-gradient(
      180deg,
      rgb(255, 255, 255),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.1)
    );
    z-index: 4;
    text-align: center;
    pointer-events: none;

    svg {
      height: 22px;
      width: 22px;
    }
  }
}
