
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  height: 100%;
  width: 100%;
}
.modalContainer {
  width: 100%;
  max-width: 600px;
  min-height: 200px;
  max-height: 90%;
}
