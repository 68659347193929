
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(inline-flex, row, center, space-between);
  padding: 8px 12px;
  width: 100%;

  .controls {
    @include flex-container(inline-flex, row, center);
    gap: 5px;
  }

  .subscribersButton {
    padding: 8px;

    strong {
      margin-right: 3px;
    }
  }
}
