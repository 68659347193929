
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.dropdownContainer {
  flex: 1;
  .dropdown-container {
    width: 100%;
    min-height: 60px;
    border: none;
    padding: 0;
    z-index: 6;
    background-color: $white100;
    overflow-y: auto;
  }

  .disabled {
    pointer-events: none;
    cursor: default;
  }
}
