
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  border: 1px solid $gray300;
  box-shadow: 0 4px 24px $shadow100, 0 2px 3px $shadow200;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  background-color: $white100;
}
