
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex);
  width: 100%;
  max-width: max-content;

  .preview {
    @include flex-container(flex, row, center, center);
    width: 80px;
    height: 80px;
    position: relative;
    background: $white400;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 4px;
  }

  .removeButton {
    @include flex-container(flex, row, center, center);
    position: absolute;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    background-color: $white400;
    right: -10px;
    top: -10px;
    width: 28px;
    height: 28px;
    z-index: 2;

    svg {
      width: 12px;
      height: 12px;
    }

    &:hover svg path {
      fill: rgba($blue300, 0.6);
    }

    &:disabled {
      @include prevent-hover;
    }
  }

  .loader {
    border-radius: 4px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
