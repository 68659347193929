
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column);
  width: 100%;

  .entriesContainer {
    width: 100%;
    height: auto;
    flex: 1;
    overflow-y: hidden;
  }

  .messageArea {
    flex-shrink: 0;
    width: 100%;
  }

  .conversationContainer {
    @include flex-container(flex, column);
    flex-wrap: wrap;
    width: 100%;
    height: calc(100% - 60px);
  }
}
