
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(inline-flex, row, center, center);
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid $gray300;
  box-shadow: 0 1px 1px $shadow100;
  width: 40px;
  height: 40px;
  font-weight: bold;
  background-color: $white100;
  color: $blue500;
  font-size: 14px;
  cursor: default;

  svg {
    max-width: 16px;
    max-height: 16px;
  }

  &.small {
    width: 32px;
    height: 32px;
  }

  &.large {
    width: 48px;
    height: 48px;
  }
}
