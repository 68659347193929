
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  background-color: $white300;
  height: 100%;
  width: 100%;

  .departmentsContainer {
    @include flex-container(flex, row, center, flex-end);
    flex-wrap: wrap;
    padding: 8px 0px;
  }

  .actionButtonsContainer {
  }

  .menuItem {
    border-top: 1px solid $gray300;
  }
}
