
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  width: 100%;
  @include flex-container(flex, row, flex-start);

  .description {
    margin-left: 12px;
    width: 100%;
    overflow: hidden;

    .ownerName {
      color: $blue200;
    }
    .fileName {
      @include text-ellipsis;
    }
  }

  .preview {
    height: 64px;
    width: 64px;
    flex-shrink: 0;
  }

  .downloadButton {
    padding: 0 !important;
    height: auto !important;
    margin-left: 15px;

    &:hover {
      background: none;
    }
  }
}
