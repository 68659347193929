
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, center);
  height: 44px;
  background-color: $white200;
  border-bottom: 1px solid $gray300;

  .name {
    flex: 1;
    text-align: center;
    color: $blue300;
  }
}
