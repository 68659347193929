
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  position: relative;
  @include flex-container(flex, column, initial, initial);
  width: 100%;
  height: 100%;
  background-color: $white200;

  .channelsHeader {
    display: flex;

    > button {
      margin-left: 8px;
    }
  }

  .emptyMessage {
    @include flex-container(flex, row, center, center);
    color: rgba($blue500, 0.7);
    height: 100%;
  }

  .starredMessagesContainer,
  .channelContainer {
    flex: 1;
  }
}
