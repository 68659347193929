@font-face {
  font-family: Rubik;
  src: local('Rubik'), url('../assets/fonts/Rubik.ttf') format('truetype');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: local('RubikBold'), url('../assets/fonts/RubikBold.ttf') format('truetype');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: AtkinsonHyperlegibleRegular;
  src: local('AtkinsonHyperlegibleRegular'),
    url('../assets/fonts/AtkinsonHyperlegible.ttf') format('truetype');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: AtkinsonHyperlegibleBold;
  src: local('AtkinsonHyperlegibleBold'),
    url('../assets/fonts/AtkinsonHyperlegibleBold.ttf') format('truetype');
  font-weight: bold;
  font-display: swap;
}
