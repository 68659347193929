
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, center, space-between);
  width: 100%;
  height: 60px;
  background-color: $white100;
  border-bottom: 1px solid $white400;
  position: relative;

  .nameContainer {
    padding: 12px 16px;
  }

  .newChatButton {
    margin-right: 16px;
  }

  .h4 {
    line-height: 18px;
  }

  h5 {
    color: $blue500;
    opacity: 0.5;
  }

  .controls {
    @include flex-container(flex, row, center);

    .userButton {
      box-shadow: -1px 0px 0px $gray300;
      border-radius: 0;
      height: 60px;
      width: 60px;
      margin-left: 12px;
    }
  }
}
