
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.text,
.info,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $fontPrimary;
  color: $blue500;

  &.bold {
    font-weight: bold;
  }

  &.normal {
    font-weight: normal;
  }
}

.text {
  font-size: 16px;
  line-height: 22px;
}

.link {
  color: $blue600;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    @include button-hover;
    text-decoration: none;
  }

  &:active {
    @include button-active;
  }
}

.info {
  color: rgba($blue500, 0.7);
}

.h1 {
  font-size: 28px;
  line-height: 32px;
}

.h2 {
  font-size: 24px;
  line-height: 28px;
}

.h3 {
  font-size: 20px;
  line-height: 24px;
}

.h4 {
  font-size: 16px;
  line-height: 22px;
}

.h5 {
  font-size: 14px;
  line-height: 18px;
}

.h6 {
  font-size: 12px;
  line-height: 14px;
}
