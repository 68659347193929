
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  width: 100%;

  .selectContainer {
    width: 100%;
    flex: 1;
  }

  .contentContainer {
    width: 100%;
  }

  .emptyMessage {
    min-height: 60px;
    @include flex-container(flex, row, center, center);
  }
}

.selectField {
  width: 100%;
  .arrowIcon {
    @include flex-container(flex, row, center, center);
    svg path {
      fill: $blue100;
    }
  }

  input {
    cursor: pointer;
  }
}

.menu {
  border-radius: 0 !important;
}
