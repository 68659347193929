
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, center, flex-start);
  flex-wrap: wrap;
  background-color: $white100;
  border-bottom: 1px solid $white400;
  padding: 12px 16px;

  .controls {
    @include flex-container(flex, row, center);
    margin-left: 7px;
    gap: 7px;
    order: 2;
  }

  .searchControls {
    @include flex-container(flex, row, center);
    gap: 7px;
    width: 100%;
    margin-top: 4px;
  }

  .select {
    width: auto;
    flex: 1 0 auto;
    order: 1;
  }
}
