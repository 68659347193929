
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column);
  width: 100%;
  height: calc(100% - 60px);

  .toolbar {
    border-bottom: 1px solid $white400;
  }
}
