
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, center);
  box-shadow: inset 0px -1px 0px $gray400;
  padding: 8px 16px 8px 16px;
  gap: 16px;

  .titleContainer {
    flex: 1;
  }
}
