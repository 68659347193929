
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  width: 100%;
  .messageAreaContainer {
    @include element-transition;
    box-shadow: 0px -1px 0px $gray100;
    position: relative;
    z-index: 1;

    &.error {
      border-top: 1px solid $red100;
    }

    .messageArea {
      box-sizing: border-box;
      padding: 12px 16px;
      width: 100%;
      position: relative;
      background-color: $white100;
      border-radius: 6px;

      .textField {
        width: 100%;
        font-size: 16px;
        max-height: 200px;
        font-weight: 500;
        resize: none;
        border: none;
        outline: none;
      }

      .downBarContainer {
        @include flex-container(flex, row, center, space-between);
      }

      .sendOptionsTitle {
        font-size: 14px;
      }

      .filesContainer {
        display: flex;
        gap: 12px;
        padding: 15px 0;
        flex-wrap: wrap;
        //overflow-x: auto;

        .filePreview {
          margin-right: 9px;
        }
      }

      .sendOptionsDropdown {
        min-width: 250px;
      }
    }
  }

  .dragableArea {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .label {
    margin-bottom: 5px;
  }

  .info {
    padding: 8px 12px;
    color: rgba($blue500, 0.5);
    width: 100%;
    border-top: 1px solid $gray100;
    background-color: $white200;
    border-radius: 0 0 6px 6px;

    a {
      color: rgba($blue500, 0.5);
    }
  }

  .leftControls {
    @include flex-container(flex, row, center, flex-end);
    margin-left: auto;
    margin-right: 34px;
    gap: 15px;

    .hint {
      @include flex-container(flex, row, center, center);
      color: $blue500;

      p {
        font-size: 14px;
      }

      svg {
        margin-right: 6px;
        path {
          fill: $gray100;
        }
      }
    }
  }

  .rightControls {
    @include flex-container(flex, row, center, center);
    gap: 16px;
  }
}
