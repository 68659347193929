
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  position: relative;
  height: calc(100% - 102px);
  width: 100%;
  .filesContainer {
    padding: 12px;
    .filePreview {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
