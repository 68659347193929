@import url('fonts.scss');

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::placeholder {
  font-family: 'Rubik', sans-serif;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

a {
  text-decoration: none;
}

html,
body,
#root {
  height: 100%;
}
