
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column, flex-end);
  width: 100%;
  height: 70px;
  border-radius: 8px;
  padding: 3px 8px;

  &.info {
    background-color: $blue700;

    .durationIndicator {
      background-color: rgba($black100, 0.9);
    }
  }

  &.success {
    background-color: $green200;

    .durationIndicator {
      background-color: rgba($black100, 0.25);
    }
  }

  &.warning {
    background-color: $yellow300;
    color: $blue500;

    .durationIndicator {
      background-color: rgba($black100, 0.12);
    }

    .content {
      color: $blue500;
    }
  }

  &.error {
    background-color: $red200;

    .durationIndicator {
      background-color: rgba($black100, 0.25);
    }
  }

  .content {
    @include flex-container(flex, row, center, space-between);
    color: $white100;
    width: 100%;
    height: 100%;

    p {
      @include text-ellipsis-multiline;
      padding: 0 16px;
    }
  }

  .durationIndicatorContainer {
    position: relative;
    width: 100%;
    padding: 0 8px;
  }
  .durationIndicator {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    height: 6px;
    border-radius: 14px;
    animation-name: fadeOut;
    animation-timing-function: linear;
  }

  @keyframes fadeOut {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
}
