
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, center, center);
  background: $white400;
  height: 100%;
  border-radius: 4px;

  video {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
  }
}
