
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column, center, space-between);
  @include element-transition;

  width: 100%;
  background-color: $white400;
  font-family: AtkinsonHyperlegibleBold, sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: $blue300;
  padding: 16px 0;
  text-align: center;
  cursor: pointer;

  svg path {
    fill: $blue300;
  }

  &:hover {
    background-color: rgba($blue300, 0.1);
  }

  &.active {
    color: $white100;
    background-color: $blue400;

    svg path {
      fill: $white100;
    }
  }
}
