
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column, center, center);

  .header {
    @include flex-container(flex, row, center, center);
    padding: 12px 16px;
    width: 100%;
    border-bottom: 1px solid $gray300;

    .phoneField {
      width: 100%;
    }
  }

  .content {
    @include flex-container(flex, column, center, center);
    flex: 1;
    position: relative;
    background-color: $white300;
    height: 100%;
    width: 100%;

    .searchHint {
      @include flex-container(flex, row, center);
      position: absolute;
      padding: 13px 7px;
      top: 0;
      right: 0;
      gap: 4px;

      svg {
        path {
          fill: $gray100;
        }
      }

      p {
        font-size: 14px;
      }
    }

    .messageHint {
      @include flex-container(flex, column, center, center);
      max-width: 320px;

      svg {
        margin-bottom: 24px;
      }

      p {
        text-align: center;
        opacity: 0.7;
      }
    }
  }

  .messageAreaContainer {
    width: 100%;
  }
}
