
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  height: 100%;

  .navBar {
    height: 44px;
    padding: 12px 16px;

    button > div {
      padding: 0 !important;
    }
  }

  .previewButton {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 8;
  }

  .content {
    height: calc(100% - 44px);
    position: relative;
  }

  .openedMenu {
    position: fixed;
    z-index: 7;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
