
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  position: relative;
  @include flex-container(flex, row, center, center);
  width: 100%;
  height: 100%;

  .content {
    max-width: 400px;
    width: 100%;
    padding: 16px;

    .header {
      margin-bottom: 48px;
    }

    .field {
      margin-bottom: 12px;
    }

    .errorMessage {
      margin-bottom: 15px;
      color: $red100;
    }
  }
}
