.selectBase {
  width: 100%;
  .label {
    margin-bottom: 5px;
  }

  .emptyMessage {
    padding: 8px 12px;
    text-align: center;
  }

  input::placeholder {
    opacity: 1;
    color: $blue500;
  }

  .searchField {
    .arrowIcon {
      @include flex-container(flex, row, center, center);
      svg path {
        fill: $blue100;
      }
    }
  }
}
