
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, center, center);
  height: 100%;
  width: 100%;
  flex: 1;
}
