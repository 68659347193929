
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column);
  width: 100%;

  .searchContainer {
    @include flex-container(flex, row, center);
    width: 100%;
    padding: 12px 16px;
    background-color: $white200;
    border-bottom: 1px solid $gray300;
  }

  .subscribersList {
    position: relative;
    width: 100%;
    height: 100%;

    .unsubscribeButton {
      font-size: 14px;
      width: 105px;
      text-align: center;
    }

    .removeButton {
      color: $red100;
      font-weight: bold;
    }
  }
}
