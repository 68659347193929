
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header {
    @include flex-container(flex, row, center, space-between);
    cursor: pointer;
    padding: 4px 0;

    .headerLeft {
      @include flex-container(flex, row, center, flex-start);
      gap: 10px;
    }

    .headerRight {
      @include flex-container(flex, row, center, flex-start);
      gap: 10px;
    }
  }

  .content {
    max-height: 256px;
    padding: 0 16px;
    overflow: auto;
  }
}
