
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column, center, center);
  height: 100%;
  width: 100%;

  .header {
    @include flex-container(flex, row, center, space-between);
    width: 100%;
    padding: 0 16px;
    height: 50px;
    box-shadow: inset 0 0px 0 $white600, inset 0px -1px 0px $white600;

    .closeButton > div {
      padding: 0 !important;
    }
  }

  .title {
    @include flex-container(flex, row, center);
    gap: 8px;
  }
}
