
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column);
  padding: 16px 24px;
  width: 100%;
  flex: 1;

  .detailsItem {
    @include flex-container(flex, column);
    margin-bottom: 16px;
    width: 100%;
    word-break: break-word;
  }

  .detailsItemHeader {
    color: $blue200;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .tags {
    @include flex-container(flex, row);
    flex-wrap: wrap;
    gap: 5px;
  }
}
