
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, row, center, center);
  background-color: rgba($white100, 0.7);
  animation: fadeIn ease-in 0.05s;
  will-change: opacity;
  animation-fill-mode: forwards;

  &.removing {
    animation: fadeOut ease-out 0.1s;
  }

  svg {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &.fullSize {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 3;
    height: 100%;
  }

  .loader {
    @include flex-container(flex, row, center, center);

    svg {
      width: 28px;
      height: 28px;
    }

    p {
      margin-left: 10px;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
