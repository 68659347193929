
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
@import '../Selects.module';

.container {
  @extend .selectBase;

  .label {
    margin-bottom: 5px;
  }

  .optionsContainer {
    display: flex;
    border: 1px solid $gray100;
    border-radius: 4px;
    position: relative;
    background-color: $white100;

    &:focus-within {
      border: 1px solid $blue300;
    }

    &.small {
      .tagsList {
        padding: 4px 12px 0;
      }

      .tagsInput {
        font-size: 14px;
      }
    }

    &.medium {
      .tagsList {
        padding: 5px 12px 0;
      }

      .tagsInput {
        font-size: 16px;
      }
    }

    &.withIcon {
      padding-left: 28px;

      .icon {
        position: absolute;
        left: 12px;
        top: 9px;

        svg {
          max-width: 16px;
          max-height: 16px;
        }
      }
    }

    .textField input {
      border: none;

      &:focus {
        border: none;
      }
    }

    .tagsContent {
      width: 100%;

      .tag {
        margin: 0 5px 5px 0;
      }
    }
  }

  .dropdownContainer {
    width: 100%;

    .menuContainer {
      min-height: 60px;
    }
  }

  .menuListContainer {
    height: 100%;
  }

  .optionsListContainer {
    max-height: 200px;
  }
}
