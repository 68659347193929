
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: $white100;
}
