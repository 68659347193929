
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  @include flex-container(flex, column, center, space-between);
  @include element-transition;
  padding: 8px 16px;
  cursor: pointer;
  width: 106px;
  height: 98px;

  .logo {
    @include flex-container(flex, row, center, center);
    position: relative;
    color: $blue300;
    font-size: 32px;
    line-height: 39px;
    width: 48px;
    height: 48px;
    background-color: $white100;
    border-radius: 7px;
    font-family: AtkinsonHyperlegibleBold, sans-serif;
  }

  .title {
    @include text-ellipsis-multiline;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    font-weight: bold;
    color: $blue300;
    margin-top: 4px;
    width: 100%;
    word-break: break-word;
  }

  &:hover,
  &.active {
    .logo,
    .title {
      color: $blue600;
    }
  }

  .badge {
    @include flex-container(flex, row, center, center);
    position: absolute;
    top: 3px;
    right: 3px;
    width: 8px;
    height: 8px;
    background-color: $orange100;
    box-shadow: 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 50%;
  }
}
