
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
          @import "./src/styles/variables.scss";
        
.container {
  height: 100%;
  background-color: $white100;
  .entriesContainer {
    padding: 16px;
  }

  .message {
    margin-bottom: 16px;
  }
}
